<template>
  <div class="Workspace">
    <header class="Workspace__header">
      <div
          class="Workspace__header-container d-flex justify-space-between align-center"
      >
        <div class="d-flex align-center">
          <p class="Workspace__header-title">
            <router-link
                to="/workspaces"
                tag="a"
                class="Workspace__header-title-link"
            >
              Workspaces
            </router-link>
            <v-icon small class="Workspace__header-title-icon">
              mdi-chevron-right
            </v-icon>
            <span
                class="Workspace__header-title-name"
            >
              {{ workspace.name }}
            </span>
          </p>
        </div>
        <v-btn
            @click.stop="updateWorkspaceInfo"
            :loading="updateWorkspaceLoading"
            class="item"
            outlined
            dense
            name="Save"
            label="Save" >
          Save
        </v-btn>
      </div>
    </header>
    <v-col v-if="!workspaceLoading">
      <div style="display: flex; flex-direction: row; justify-content: space-around; padding: 25px">
        <div style="flex-grow: 0; min-width: 50%">
          <span style="font-size: 14pt">
            {{ workspace.description }}
          </span>
          <v-row class="Workspace__messageInput">
            <v-col>
              <v-textarea
                  class="TextArea"
                  outlined
                  dense
                  :placeholder="'Message'"
                  hide-details="auto"
                  novalidate
                  :rows="5"
                  v-model="message"
                  id="message_input2"
                  @keydown.enter.prevent=""
                  :loading="sendMessageLoading"
              >
              </v-textarea>
              <v-row class="Workspace__files" v-if="filesAccumulated.length !== 0">
                <div class="Workspace__filesContainer">
                  <div v-for="(file, index) in filesAccumulated" :key="index" class="fileItem">
                    <div style="position:relative; display: flex; justify-content: start">
                      <button @click.stop="onFileDelete(index)" class="closeButton">
                        <span>&times;</span>
                      </button>
                      <img v-if="file.type.includes('image')" :src="createObjectURL(file)" class="image"/>
                      <div style="height: 80px">
                        <img v-if="!file.type.includes('image')" class="document" :src="`${publicPath}/icons/file-icon.svg`"/>
                      </div>
                      <span style="top: 0px">
                   {{ file.name }}
                 </span>
                    </div>
                  </div>
                </div>
              </v-row>
            </v-col>
            <v-col style="max-width: 3%">
              <v-row style="margin-top: 3px">
                <v-file-input
                    label="File input"
                    hide-input
                    v-model="files"
                    class="Workspace__attach_button"
                    multiple
                    accept=".doc,.docx,.pdf,.csv,.txt,.html,.odt,.rtf,.epub,.jpeg,.png,.jpg,image/*"
                    @change="onFileChange"
                ></v-file-input>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div style="flex-grow: 0">
          <div class="Workspace__settings">
            <div class="section">
              <div class="title">
                Chat Model
              </div>
              <div class="content">
                <v-autocomplete
                    class="mb-3"
                    style="width: 250px"
                    outlined
                    dense
                    label="AI Model"
                    :items="models"
                    v-model="selectedModel"
                    item-text="label"
                    item-value="value"
                    @change="(value) => {}"
                ></v-autocomplete>
              </div>
            </div>
            <div class="section">
              <div class="title">
                Tools
                <v-btn style="" icon @click.stop="isToolSelectDialog = true">
                  <v-icon small> mdi-pencil-outline </v-icon>
                </v-btn>
              </div>
              <div class="content">
                <div class="selectedToolsList">
                  <div class="selectedToolItem" v-for="tool in workspace.tools" :key="tool.id">
                    {{ tool.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="section">
              <div class="title">
                System Prompt
              </div>
              <div class="content">
                <v-row>
                  <v-col>
                    <v-textarea
                        class="TextAreaSystemPrompt"
                        outlined
                        dense
                        :placeholder="'System Prompt'"
                        hide-details="auto"
                        novalidate
                        :rows="5"
                        v-model="systemPromptText"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-autocomplete
                          class="mb-3 mt-3"
                          outlined
                          dense
                          clearable
                          label="System Prompts"
                          :items="systemPrompts"
                          v-model="selectedSystemPrompt"
                          item-text="name"
                          item-value="id"
                          @change="(value) => updateSelectedSystemPrompt(value)"
                          :append-outer-icon="systemPromptLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
                          @click:append-outer="this.getSystemPrompts"
                      ></v-autocomplete>
                    </v-row>
                    <v-row>
                      <v-btn @click.stop="saveSystemPrompt()" class="CreateWorkspace__sendButton" :loading="updateSystemPromptLoading">
                        Save
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="section">
              <div class="title">
                Max Tokens
              </div>
              <div class="content">
                <div class="maxTokens">
                  <v-slider
                      v-model="workspace.maxTokens"
                      :min="1000"
                      :max="15000"
                  ></v-slider>
                  <span>
                    {{ workspace.maxTokens }}
                  </span>
                </div>
              </div>
            </div>
            <div class="section">
              <div class="title">
                Number of latest included messages
              </div>
              <div class="content">
                <div class="maxTokens">
                  <v-slider
                      v-model="historyLimit"
                      :min="0"
                      :max="50"
                      :disabled="includeAllHistory"
                  ></v-slider>
                  <span>
                      {{ historyLimit }}
                  </span>
                </div>
                <v-checkbox v-if="isShAdmin()" label="Include All" v-model="includeAllHistory">
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-row class="Workspace__sessions">
        <div class="titleWrapper">
          <span class="title">
            Your sessions
          </span>
        </div>
        <div class="list">
          <div class="item" v-for="session in sessions" :key="session.id" @click="goToSession(session.id)">
            <span>
              {{ session.name }}
            </span>
            <span>
              {{ getTime(session.createdAt) }}
            </span>
          </div>
        </div>
      </v-row>
    </v-col>
    <v-col v-if="workspaceLoading">
      <Loader></Loader>
    </v-col>
    <tools-select :dialog="isToolSelectDialog" :toolSets="workspace.tools" @close="isToolSelectDialog = false" @toolsChanged="(data) => { workspace.tools = data}"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
  mapMutations: AIChatMutations
} = createNamespacedHelpers('AIChat');
const { mapGetters: workspacesGetters, mapActions: workspaceActions } = createNamespacedHelpers('workspace');

import debounce from "lodash/debounce";

import * as moment from 'moment';
import ToolsSelect from "@/views/ai-workspaces/create-workspace/tools-select/tools-select.vue";
import Loader from "@/components/loader/loader.vue";
import lodashGet from "lodash/get";
import constants from "@/util/constants";

export default {
  name: 'WorkspaceDetails',
  components: {ToolsSelect, Loader},
  data() {
    return {
      message: '',
      publicPath: constants.isDevelopment ? "" : "",
      includeAllHistory: false,
      isToolSelectDialog: false,
      historyLimit: 15,
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        {
          label: 'Claude V3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
        },
        {
          label: 'Claude V2',
          value: 'anthropic.claude-v2:1'
        }
      ],
      selectedModel: 'anthropic.claude-3-haiku-20240307-v1:0',
      files: [],
      filesAccumulated: [],
      selectedSystemPrompt: null,
      systemPromptText: '',
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
      ...AIChatGetters({
        systemPrompts: 'SYSTEM_PROMPTS',
        systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
        updateSystemPromptLoading: 'UPDATE_SYSTEM_PROMPT_LOADING',
        sendMessageLoading: 'SEND_MESSAGE_LOADING',
      }),
    ...workspacesGetters({
      workspace: 'WORKSPACE',
      sessions: 'WORKSPACE_SESSIONS',
      updateWorkspaceLoading: 'WORKSPACE_UPDATE_LOADING',
      workspaceLoading: 'WORKSPACE_LOADING',
      sessionsLoading: 'WORKSPACE_SESSIONS_LOADING'
    })
  },
  watch: {
    'selectedCustomer.customer_id'() {
      //this.getInfo();
    },
  },
  methods: {
    ...AIChatActions(['sendMessage', 'getToolSets', 'getSystemPrompts', 'updateSystemPrompt', 'createSystemPrompt']),
    ...AIChatMutations({
      setSendMessageLoading: 'SET_SEND_MESSAGE_LOADING',
      setSendMessage: 'SET_SEND_MESSAGE',
      setChatWorkspace: 'SET_CHAT_WORKSPACE',
    }),
    ...workspaceActions(['getWorkspace', 'getWorkspaceSessions', 'updateWorkspace']),
    createObjectURL(file) {
      return URL.createObjectURL(file)
    },
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    async saveSystemPrompt() {
      if(this.selectedSystemPrompt?.id) {
        await this.updateSystemPrompt({
          systemPromptId: this.selectedSystemPrompt.id,
          prompt: this.systemPromptText,
        });
        return;
      }
      const prompt = await this.createSystemPrompt({
        prompt: this.systemPromptText,
        name: this.systemPromptText,
      })
      this.systemPrompts.push(prompt);
      this.selectedSystemPrompt = prompt;
      this.systemPromptText = prompt.prompt;
    },
    async updateSelectedSystemPrompt(value) {
      const systemPrompt = this.systemPrompts.find((prompt) => prompt.id === value);
      this.systemPromptText = systemPrompt.prompt;
      this.selectedSystemPrompt = systemPrompt;
    },
    getTime(date) {
      return moment(date).fromNow()
    },
    onFileDelete(index) {
      this.filesAccumulated.splice(index, 1);
      this.files = [];
    },
    onFileChange(event) {
      event.forEach(thisFile => {
        if(this.validateSize(thisFile)) {
          this.$store.dispatch('notifications/setError',`${thisFile.name} too big`)
          return;
        }
        this.filesAccumulated.push(thisFile)
      })
      this.files = [];
    },
    async prepareFiles() {
      const prepared = await Promise.all(this.filesAccumulated.map(async (file) => {
        const base64 =  await this.toBase64(file)
        return {
          base64: base64,
          name: file.name,
          type: file.type
        }
      }));
      return prepared;
    },
    validateSize(input) {
      const fileSize = input.size / 1024 / 1024; // in MiB
      return fileSize > 1;
    },
    debounceInput: debounce(function (e) {
      this.search = e;
    }, 1000),
    async getInfo(workspaceId) {
      this.setSendMessageLoading(false);
      await this.getWorkspace({ workspaceId });
      await this.getWorkspaceSessions({ workspaceId })
      this.selectedSystemPrompt = this.workspace?.SystemPrompt;
      this.systemPromptText = this.selectedSystemPrompt?.prompt
      this.selectedModel = this.models.find((model) => model.value === this.workspace.model).value;
      this.historyLimit = this.workspace.historyLimit
      this.includeAllHistory = this.workspace.historyLimit === null || this.workspace.historyLimit === undefined;
      await this.getSystemPrompts();
    },
    async updateWorkspaceInfo() {
      await this.updateWorkspace({
        workspaceId: this.workspace.id,
        toolSetIds: this.workspace.tools.map(tool => tool.id),
        systemPromptId: this.selectedSystemPrompt?.id,
        model: this.selectedModel,
        name: this.workspace.name,
        maxTokens: this.workspace.maxTokens,
        historyLimit: this.includeAllHistory ? null : this.historyLimit,
      });
    },

    async send() {
      if(this.message === '') {
        return;
      }
      const files = await this.prepareFiles();
      const messageValue = this.message.valueOf();
      this.message = '';
      this.setSendMessageLoading(true);

      this.setChatWorkspace(this.workspace)
      this.setSendMessage({
        workspaceId: this.workspace.id,
        workspace: this.workspace,
        files,
        messageValue,
      })

      this.$router.push({ path: `/ai-chat` });
    },

    goToSession(id) {
      this.$router.push({ path: `/ai-chat`, query: { session: id } });
    }
  },
  async mounted() {
    const { workspaceId } = this.$route.params;
    await this.getInfo(workspaceId);
    document.getElementById('message_input2').addEventListener("keydown", async (e) => {
      if (e.key == "Enter" && e.ctrlKey == false &&  e.shiftKey == false && this.message.replaceAll('\n','') !== '') {
        await this.send()
      }
      if(e.key == "Enter" && e.ctrlKey == true) {
        this.message += '\n';
        return;
      }
      if(e.key == "Enter" && e.shiftKey == true) {
        this.message += '\n';
        return;
      }
    })
    document.getElementById('message_input2').onpaste = (event) => {
      const items = (event?.clipboardData || event?.originalEvent?.clipboardData).items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          this.filesAccumulated ??= [];
          this.filesAccumulated.push(blob)
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import './workspace';
</style>
